


























































































































































































































import Vue from 'vue';
import { Context } from '@nuxt/types';
import moment from 'moment';
import News from './component/News.vue';
import { ResponseCode } from '~/util/constant';
import FairItem from '~/pages/home/component/FairItem.vue';
import { TraceCode } from '~/util/trace';
import { checkDevice, handleNewsUrl } from '~/util/common';

// const description = (content: string): string => {
//   try {
//     const reg = /<[^>]+>/gi;
//     const description = content
//       .replace(reg, '')
//       .replace(/&nbsp;/g, '')
//       .trim();
//     return description.slice(0, 100) + '...';
//   } catch (e) {
//     return '';
//   }
// };

export default Vue.extend({
  components: {
    News,
    FairItem,
  },
  // 校验params参数
  validate(ctx: Context) {
    const { path } = ctx.route;
    if (path === '/information' || path === '/information/') {
      return true;
    }
    const _validate = /information-(\d+)-(\d+)-(\d+)/.test(path);
    return _validate;
  },
  filters: {
    formatIndustry(val: string): string {
      if (!val) return '';
      const vals = val.split('/');
      return vals.slice(0, 2).join('/');
    },
    subIndustry(idx: number) {
      const index = idx % 5;
      switch (index) {
        case 0:
          return 'transform: translateX(-8%);';
        case 1:
          return 'transform: translateX(-16%);';
        case 2:
          return 'transform: translateX(-42%);';
        case 3:
          return 'transform: translateX(-22%);';
        case 4:
          return 'transform: translateX(-58%);';
      }
    },
  },
  async asyncData(ctx) {
    try {
      const lang = ctx.query.lang;
      let langApi = 'cn';
      if (lang == 'zh') langApi = 'cn';
      if (lang == 'en') langApi = 'en';
      // t 默认应该为1
      const { t = '1', i = '0', page = '1' } = ctx.params;

      const pageNum = isNaN(+page) ? 1 : +page;
      const pageSize = 15;
      // 资讯类型： 0:展会资讯 industry 1:行业动态 industryNews 2:展会政策 policy 3:展会知识 knowledge
      const listParams: Record<string, any> = {
        locale: langApi,
        // newsType: t,
        pageNum: pageNum > 1 && i === '0' ? pageNum + 1 : pageNum,
        pageSize,
      };

      if (+i !== 0) {
        listParams.industryId = +i;
      }

      const fetchArr = [
        ctx.$axios.get(`/api/industry/getAll?locale=${langApi}`),
        ctx.$axios.post('/api/newsApi/homePageList', listParams),
        ctx.$axios.post('/api/exhibition/page', {
          locale: langApi,
          pageNum: 1,
          pageSize: 10,
        }),
      ];
      if (+i === 0) {
        fetchArr.push(
          ctx.$axios.post('/api/newsApi/homePageList', {
            ...listParams,
            pageNum: 2,
          })
        );
      }

      const result: any[] = await Promise.all(fetchArr);

      const [industryRes, newsRes, newFairRes = {}, secondRes = {}] = result;

      let industry = [];
      if (industryRes.code === ResponseCode.SUCCESS) {
        industry = industryRes.data;
      }

      let news = [];
      let newsTotal = 0;
      if (newsRes.code === ResponseCode.SUCCESS) {
        newsTotal = newsRes.data.total;

        news = newsRes.data.records.map((item: any) => {
          return {
            newsId: item.newsId,
            viewUrl: item.viewUrl,
            newsName: item.newsName,
            logo: item.logo,
            industryName: item.industryName,
            industryId: item.industryId,
            createTime:
              +i > 0 || pageNum > 1
                ? moment(item.publishTime).format('MM-DD HH:mm:ss')
                : moment(item.publishTime).format('HH:mm:ss'),
            views: item.views,
            description: item.description ? `${item.description}...` : '',
            specialTopics: item.specialTopics,
          };
        });
        if (pageNum > 1 && pageNum > newsRes.data.pages) {
          return ctx.error({
            message: 'Page not found',
            path: ctx.route.fullPath,
            statusCode: 404,
          });
        }
      }

      let fair = [];
      if (newFairRes.code === ResponseCode.SUCCESS) {
        fair = newFairRes.data.records;
      }

      let secondNews = [];
      if (secondRes.code === ResponseCode.SUCCESS) {
        secondNews = secondRes.data.records.map((item: any) => {
          return {
            newsId: item.newsId,
            viewUrl: item.viewUrl,
            newsName: item.newsName,
            logo: item.logo,
            industryName: item.industryName,
            industryId: item.industryId,
            createTime: moment(item.publishTime).format('MM-DD HH:mm:ss'),
            views: item.views,
            description: item.description ? `${item.description}...` : '',
            specialTopics: item.specialTopics,
          };
        });
      }

      return {
        newsTotal,
        pageNum,
        pageSize,
        newsType: t,
        industryId: +i,
        news,
        secondNews,
        industry,
        fair,
      };
    } catch (e) {
      ctx.error(e as any);
    }
  },
  data() {
    return {
      tags: [this.$t('news.notMultiple')],
      newsType: '0',
      industryId: '0',
      pageNum: 1,
      news: [],
      secondNews: [],
      newsTotal: 0,
      fair: [],
    };
  },
  mounted() {
    if (checkDevice() == 'h5') {
      let url = `https://m.jufair.com/information`;
      window.location.replace(url);
    }
  },
  methods: {
    handleNewsUrl,

    handleClick(tab: any) {
      let path = `/information-${tab.name}-${this.industryId}-1/`;
      if (tab.name === '0' && this.industryId === '0') {
        path = '/information/';
      }
      this.$router.push({
        path,
      });
    },
  },
  head() {
    const { pageNum, industry = [] } = this.$data;

    const { t = '1', i = '0' } = this.$route.params;
    let industryName = '';
    for (let m = 0; m < industry?.length; m++) {
      const children = industry[m].children;
      if (children) {
        for (let n = 0; n < children.length; n++) {
          if (children[n].id === +i) {
            industryName = children[n].industryName;
            break;
          }
        }
      }

      if (industryName) {
        break;
      }
    }

    let title = `展会资讯_新闻_攻略_报道${
      pageNum > 1 ? `_第${pageNum}页` : ''
    }-聚展`;
    let keywords = '';
    let description = `聚展网是国内专业国际展会一站式服务平台,提供最新最全的${new Date().getFullYear()}年国际展会咨询,新闻报道,出行攻略,搭建资讯,全球展会信息平台,国外展会信息,免费咨询。`;
    if (i && i !== '0') {
      title = `最新${industryName}展新闻_${industryName}展最新消息-聚展`;
      keywords = `${industryName}展新闻,${industryName}展最新消息,${industryName}展报道`;
      description = `聚展网是国内专业国际展会一站式服务平台,提供最新最全的2022年${industryName}展咨询,${industryName}展新闻报道,${industryName}展出行攻略,${industryName}展搭建资讯,全球展会信息平台,国外展会信息,免费咨询都是在聚展网`;
    }

    return {
      title,
      meta: [
        {
          name: 'keywords',
          content: keywords,
        },
        {
          name: 'description',
          content: description,
        },
        {
          name: 'og:type',
          content: 'webpage',
        },
        {
          name: 'og:url',
          content: `https://www.jufair.com${this.$route.fullPath}`,
        },
        {
          name: 'og:title',
          content: title,
        },
        {
          name: 'og:image',
          content: 'https://www.jufair.com/logo.png',
        },
        {
          name: 'og:description',
          content: description,
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: `https://www.jufair.com/information-${t}-${i}-1/`,
        },
      ],
    };
  },
});
