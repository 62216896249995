var render, staticRenderFns
import script from "./test.vue?vue&type=script&lang=ts&"
export * from "./test.vue?vue&type=script&lang=ts&"
import style0 from "./test.vue?vue&type=style&index=0&lang=less&"
import style1 from "./test.vue?vue&type=style&index=1&id=61431cbc&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61431cbc",
  null
  
)

export default component.exports