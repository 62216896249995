













































































































import Vue from 'vue';
import { Context } from '@nuxt/types';
import News from './component/News.vue';
import { ResponseCode } from '~/util/constant';

export default Vue.extend({
  components: {
    News,
  },
  // 校验params参数
  validate(ctx: Context) {
    const { path } = ctx.route;
    if (path === '/information' || path === '/information/') {
      return true;
    }
    const _validate = /information-(\d+)-(\d+)-(\d+)/.test(path);
    return _validate;
  },
  async asyncData(ctx) {
    const lang = ctx.query.lang;
    let langApi = 'cn';
    if (lang == 'zh') langApi = 'cn';
    if (lang == 'en') langApi = 'en';
    // t 默认应该为1
    const { t = '1', i = '0', page = '1' } = ctx.params;

    const pageNum = isNaN(+page) ? 1 : +page;
    const pageSize = 16;
    // 资讯类型： 0:展会资讯 industry 1:行业动态 industryNews 2:展会政策 policy 3:展会知识 knowledge
    const listParams: Record<string, any> = {
      newsType: t,
      pageNum,
      pageSize,
    };

    if (+i !== 0) {
      listParams.industryId = +i;
    }

    const result: any[] = await Promise.all([
      ctx.$axios.get(`/api/industry/getAll?locale=${langApi}`),
      ctx.$axios.post('/api/newsApi/homePageList', listParams),
    ]);

    const [industryRes, newsRes] = result;

    let industry = [];
    if (industryRes.code === ResponseCode.SUCCESS) {
      industry = industryRes.data;
    }

    let news = {};
    if (newsRes.code === ResponseCode.SUCCESS) {
      news = newsRes.data;
      if (pageNum > 1 && pageNum > newsRes.data.pages) {
        return ctx.error({
          message: 'Page not found',
          path: ctx.route.fullPath,
          statusCode: 404,
        });
      }
    }

    return {
      pageNum,
      pageSize,
      newsType: t,
      industryId: i,
      news,
      industry,
    };
  },
  data() {
    return {
      tags: ['不支持多选'],
      newsType: '0',
      industryId: '0',
      pageNum: 1,
    };
  },
  methods: {
    handleClick(tab: any) {
      let path = `/information-${tab.name}-${this.industryId}-1/`;
      if (tab.name === '0' && this.industryId === '0') {
        path = '/information/';
      }
      this.$router.push({
        path,
      });
    },
  },
  head() {
    const { pageNum } = this.$data;
    return {
      title: `展会资讯_新闻_攻略_报道${
        pageNum > 1 ? `_第${pageNum}页` : ''
      }-聚展`,
      meta: [
        {
          name: 'keywords',
          content: '',
        },
        {
          name: 'description',
          content: `聚展网是国内专业国际展会一站式服务平台,提供最新最全的${new Date().getFullYear()}年国际展会咨询,新闻报道,出行攻略,搭建资讯,全球展会信息平台,国外展会信息,免费咨询。`,
        },
      ],
    };
  },
});
